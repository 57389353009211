export const navbarData = [
    {
        routeLink:'/dashboard',
        icon:'fal fa-home',
        label:'Home'
    },
    
    {
        routeLink:'/price-approval',
        icon: 'fal fa-money-check',
        label:'Price'
    },    
    // {
    //     routeLink:'/price-ss',
    //     icon: 'fal fa-chart-pie',
    //     label:'Price'
    // },

    // {
    //     routeLink:'/user',
    //     icon:'fal fa-user',
    //     label:'User'
    // },
    // {
    //     routeLink:'/settings',
    //     icon:'fal fa-cog',
    //     label:'Settings'
    // },

    // {
    //     routeLink:'/logout',
    //     icon:'fal fa-power-off',
    //     label:'Logout'
    // },
    // {
    //     routeLink:'/dashboard1',
    //     icon:'fal fa-bell',
    //     label:'Dashboard 1'
    // },
    // {
    //     routeLink:'/dashboard2',
    //     icon:'fal fa-envelope',
    //     label:'Dashboard 2'
    // },
]