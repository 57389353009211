import { Component, EventEmitter, HostListener, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { navbarData } from './nav-data'
import { RouterModule } from '@angular/router'
import { LogoutComponent } from "../logout/logout.component"

interface NavBarToggle {
  screenWidth: number
  collapsed: boolean
}

@Component({
  selector: 'app-navigation',
  standalone: true,
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  imports: [CommonModule,
    RouterModule, LogoutComponent]
})
export class NavigationComponent {
  navData = navbarData

  navClass = "navbar-middle"
  navbarController = "nav-controller-middle"

  closeNavbar(): void {
    this.navClass = "navbar-start"
    this.navbarController = "nav-controller-start"
  }

  shortNavBar(): void {
    this.navClass = "navbar-middle"
    this.navbarController = "nav-controller-middle"
  }

  fullNavbar(): void {
    this.navClass = "navbar-end"
    this.navbarController = "nav-controller-end"
  }

}
