<div class="login-bg-container">
    <div class="left"></div>
    <div [ngClass]="line"> </div>
    <div class="right"></div>
</div>

<div class="login-component">
    <div class="left">
        <div class="logo-container">
            <div class="logo">
                <img src="../../../../assets/image/MAXIM Logo-01.png" alt="logo">
            </div>
            <div class="title-container">
                <div class="title">
                    <p>Welcome to Maxim App Portal</p>
                </div>
                <div class="message">
                    <p>Manage all MAXIM applications from one login.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="right">

        <div class="login-container">
            <span class="text-zinc-800"> Login below to get start</span>
            <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onUserLogin()">
                <div class="login-input-group">
                    <div class="login-input-icon">
                        <span class="icon"><fa-icon [icon]="faAt"></fa-icon></span>
                    </div>
                    <input class="login-input" type="text" placeholder="User Name" name="username"
                        formControlName="username">
                </div>
                <!-- <div *ngIf="isFormsSubmitedWithErrors" class="text-red-500">
                    <div *ngIf="loginForm.controls['username']?.errors?.['required']">Required.</div>
                </div> -->

                <div class="login-input-group">
                    <div class="login-input-icon">
                        <span class="icon"><fa-icon [icon]="faFingerprint"></fa-icon></span>
                    </div>
                    <input class="login-input" type="password" placeholder="Password" name="password"
                        formControlName="password" maxlength="20">
                </div>

                <!-- <div *ngIf="isFormsSubmitedWithErrors" class="text-red-500">
                    <div *ngIf="loginForm.controls['password']?.errors?.['required']">Required.</div>
                </div> -->

                <button class="login-btn">
                    <div *ngIf="isSubmited" class="loading-spinner"></div>
                    LOGIN
                </button>
            </form>

            <div class="registor-msg">
                <p>
                    <span class="">Don't you have an account?</span>
                    <a href="#">Register now</a>
                </p>
            </div>


            <div class="flex items-center">
                <div>
                    <hr class="w-36  bg-black h-0.5 opacity-40">
                </div>
                <div class="text-lg ml-5 mr-5">OR</div>
                <div>
                    <hr class="w-32  bg-black h-0.5 opacity-40">
                </div>
            </div>


            <span class="text-black ml-4">Sign in with</span>
            <div class="option ml-6">
                <div><a href=""><img src="../../../../assets/image/google.png" alt="google icon" width="25"></a></div>
                <div><a href=""><img src="../../../../assets/image/apple.png"  alt="apple icon" width="22"></a></div>
                <div><a href=""><img src="../../../../assets/image/microsoft.png"  alt="microsoft icon" width="25"></a></div>
            </div>
        </div>
    </div>

</div>
