import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { faAt, faFingerprint } from '@fortawesome/free-solid-svg-icons'
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { AuthService } from '../../services'
import { UserCredential } from '../../types/userAuth.types'
import { NotificationService } from '../notification/notification.service'

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  ngOnInit(): void {
  }

  loginForm: FormGroup
  line = "line"

  faAt = faAt
  faFingerprint = faFingerprint

  isSubmited = false

  onUserLogin() {
    this.isSubmited = true
    const credentials: UserCredential = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password
    }
    this.isSubmited = true

    this.authService.signIn(credentials)
    this.isSubmited = false

  }
}

