<!-- <div class="nav-bar" [ngClass]="collapsed ? 'nav-bar-collapsed': ''"> -->

<div class="header">
  <div class="nav-colapse">
    <button class="nav-colapse-btn" *ngIf="navClass == 'navbar-start'" (click)="shortNavBar()">
      <i class="fal fa-bars"></i>
    </button>

    <button class="nav-colapse-btn" *ngIf="navClass != 'navbar-start'" (click)="closeNavbar()">
      <i class="fal fa-times close-icon"></i>
    </button>

  </div>

  <div class="header-title">ERP - MAXIM IMPRESSION LANKA (PVT) LTD.</div>

</div>

<div class="navbar" [ngClass]="navClass">

  <ul class="nav-list">
    <li class="nav-item" *ngFor="let data of navData">
      <a class="nav-link " [routerLink]="data.routeLink" [routerLinkActive]="['is-active']" (click)="closeNavbar()">
        <i class="nav-link-icon" [class]="data.icon"></i>
        <p class="nav-link-text">{{data.label}}</p>
      </a>
    </li>
  </ul>

  <div class="signout">
    <app-logout></app-logout>
  </div>
</div>

<div class="navbar-controller" [ngClass]="navbarController">
  <button class="nav-right-btn" *ngIf="navbarController == 'nav-controller-middle'" (click)="fullNavbar()">
    <i class="fal fa-chevron-right"></i>
  </button>

  <button class="nav-right-btn" *ngIf="navbarController == 'nav-controller-end'" (click)="shortNavBar()">
    <i class="fal fa-chevron-left"></i>
  </button>
</div>