import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {  DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-menu-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss'
})
export class MenuItemComponent {
  @Input() title: string = '';
  @Input() bgColor: string = '';
  @Input() textColor: string = '';
  @Input() iconBg: string = '';
  @Input() svgContent: string = '';
  // @Input() redirectFunction: () => void;
  getSanitizedSvgContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.svgContent);
  }
  constructor(private sanitizer: DomSanitizer) {}
}
