<div class="containers" [ngClass]="bgColor">
    <div>
        <span class="text"  [ngClass]="textColor">{{ title }}</span>
    </div>
    <div>
        <div class="icon-container" [ngClass]="iconBg">
            <div [innerHTML]="getSanitizedSvgContent()"class="icon" >
            </div>
        </div>
    </div>
</div>
