import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpClient } from "@angular/common/http"
import {APIRequestResources} from "../enums"
import {APIRequest} from "../classes"
import {SignInSession, UserCredential} from "../types/auth.type"
import { JwtHelperService } from '@auth0/angular-jwt';
import {ApiResponse} from "../components/api.response";

export class AuthEndpoints {
  static readonly SIGN_IN = ''
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends APIRequest{

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.AuthService)
  }

  signIn =  (userCredential: UserCredential) => {
    this.post<SignInSession>(userCredential, { endpoint: `login`}).subscribe({
      next: (res) => {
        if(res.data) {
          sessionStorage.setItem("user-auth", JSON.stringify(res.data))
          this.router.navigate(['/dashboard'])
        }
        console.log('Sign in successful:', res)
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  validate = () => {
    return this.get({
      endpoint: `token`,
    })
  }

  isUserExist(): boolean {
    let userSessionData = sessionStorage.getItem("user-auth") ?? "NAU"
    return userSessionData != "NAU";
  }

  getAuthToken(): string {
    let userSessionData = sessionStorage.getItem("user-auth") ?? "NAU"
    if (userSessionData === "NAU") { return "" }
    let userData: SignInSession = JSON.parse(userSessionData)
    return <string>userData.accessToken
  }

  userSignOut() {
    sessionStorage.removeItem("user-auth")
    this.router.navigate(['/login'])
  }
}
