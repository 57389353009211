import {Component, ElementRef, inject, OnInit} from '@angular/core';
import {NotificationService} from '../../../../../../core/components/notification/notification.service';
import {Router} from '@angular/router';
import {MenuItemComponent} from "../../shared";
import {SignInSession} from "../../../../../../core/types/userAuth.types";
import {AuthService} from "../../../../../../core/services";
import {JwtModule} from "@auth0/angular-jwt";
import {ColorGenerationService} from "../../../../../services/ColorGeneration.service";
import {NgClass, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {Dropdown, DropdownInterface, DropdownOptions, InstanceOptions} from "flowbite";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  templateUrl: './menu-view.component.html',
  styleUrl: './menu-view.component.scss',
  imports: [MenuItemComponent, JwtModule, NgClass, NgForOf, NgIf, NgOptimizedImage]
})
export class MenuViewComponent implements OnInit{

  authService = inject(AuthService)
  userAuthData: SignInSession | undefined
  colorGenerationService = inject(ColorGenerationService);
  isUserMenuOpen = false;
  private dropdown: DropdownInterface | null = null;


  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private elementRef: ElementRef

  ) {}


  ngOnInit(): void {
    this.initDropdown();
    const sessionString = sessionStorage.getItem("user-auth")
    if (sessionString) {
      this.userAuthData = JSON.parse(sessionString)
    }
  }

  directToAppUrl(link: string) {
    window.location.href = link
  }

  async handleAppRedirect(appUrl: string) {
    let appUri = appUrl

    if (this.userAuthData) {
      this.authService.validate().subscribe({
        next: result => {
          let redirectLink = `${appUri}?auth-key=${this.userAuthData?.authKey}`
          this.directToAppUrl(redirectLink)
        },
        error: err => {
          console.log(err)
          let redirectLink = `${appUri}`
          this.notificationService.AppDirectMessage(this.directToAppUrl, redirectLink, "Your session has expired. Would you like to go to the app?", "Session Time out!", "Yes", "No")
        }
      })
    } else {
      this.notificationService.Error("System can't Identify you as a valid user", "Sorry! ")
    }
  }

  logout(): void {
    this.authService.userSignOut()
  }

  getBackgroundColor(index: number): string {
    return this.colorGenerationService.getColorForIndex(index);
  }


  initDropdown() {
    const targetEl: HTMLElement | null = this.elementRef.nativeElement.querySelector('#user-dropdown');
    const triggerEl: HTMLElement | null = this.elementRef.nativeElement.querySelector('#user-menu-button');
      this.dropdown = new Dropdown(targetEl, triggerEl);
  }
}
